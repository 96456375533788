<template>
  <div class="mdc-snackbar">
    <div class="mdc-snackbar__surface">
      <div
        class="mdc-snackbar__label"
        role="status"
        aria-live="polite"
        v-html="value"
      />
      <div class="mdc-snackbar__actions">
        <button type="button" class="mdc-button mdc-snackbar__action">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { MDCSnackbar } from '@material/snackbar';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    mdcSnackbar: null,
  }),
  watch: {
    value(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.mdcSnackbar.open();
          this.$emit('input', null);
        });
      }
    },
  },
  mounted() {
    this.mdcSnackbar = new MDCSnackbar(this.$el);
  },
  beforeDestroy() {
    this.mdcSnackbar.destroy();
  },
};
</script>
